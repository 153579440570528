import React, { useState, useEffect, useRef } from 'react';
import {
  Button,
  Table,
  ColomnProps,
  ActionBtn,
  BreadcrumbNav,
  showToast,
} from 'design-web';
import dayjs from 'dayjs';
import searchImg from '../../../assets/images/search.svg';
import { useTheme } from 'shared/src/hooks/useTheme';
import useDocumentService from 'shared/hooks/service/useDocumentService';
import Modal from 'packages/web/src/components/Modal';
import { Document } from 'shared/contexts/DocumentContext';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import DateRange from '../../../components/DateRange';
import dotAction from '../../../assets/images/dotAction.svg';
import folder from '../../../assets/images/folder-group.svg';
import document from '../../../assets/images/document.svg';
import pdfIcon from '../../../assets/images/pdf-icon.svg';
import wordIcon from '../../../assets/images/word-icon.svg';
import excelIcon from '../../../assets/images/excel-icon.svg';
import zipIcon from '../../../assets/images/zip-icon.svg';
import jpgIcon from '../../../assets/images/jpg-icon.svg';
import exeIcon from '../../../assets/images/exe-icon.svg';
import msgIcon from '../../../assets/images/msg-icon.svg';
import tifIcon from '../../../assets/images/tif-icon.svg';
import crossIcon from '../../../assets/images/crossIcon.svg';

import useBreadcrumb from 'shared/hooks/useBreadcrumbs';
import { DocumentForm } from './DocumentForm';
import useDebounce from 'shared/hooks/useDebounce';
import { useDocumentContext } from 'shared/hooks/context/useDocumentContext';
import { getTransformedUrl } from '../../../utils/utils';
import routes from 'shared/config/routes';
import {
  DateRangeType,
  DateType,
  DateValueType,
} from 'react-tailwindcss-datepicker';
import ShareModal from './ShareModal';
import CommonModal from '../../../components/DeleteModal';
import useAccessControl from 'shared/hooks/useAccessControl';
import {
  ActionPermissions,
  DocSharePermissions,
  PreviewExtensions,
} from 'shared/types/types';
import { useAuthContext } from 'shared/hooks/context/useAuthContext';
import DataSaveLoader from 'packages/web/src/components/DataSaveLoader';
import noRecords from '../../../assets/images/noRecord.svg';
import { getUserFullName } from 'shared/utils/utils';
import CustomDropdown from 'packages/web/src/components/CustomDropdown';
import { DataTableSortStatus } from 'mantine-datatable';
import { SortingDropdown } from 'packages/web/src/components/SortingDropdown';
import useInfiniteScroll from 'shared/hooks/useInfiniteScroll';
import { DocType, SearchType, SelectedTab } from 'shared/src/types/types';
import CommonSelect from 'packages/web/src/components/CommonSelect';
import axios from 'axios';
import { useAppContext } from 'shared/hooks/context/useAppContext';

type DateRangeValueType = {
  start: DateType;
  end: DateType;
};

type User = {
  id: number;
  name: string;
  email: string;
  permission?: 'Viewer' | 'Editor' | 'None';
};

export const DocumentList: React.FC = () => {
  // State hooks
  const [dateRange, setDateRange] = useState<DateRangeValueType>({
    start: null,
    end: null,
  });
  const [page, setPage] = useState(1);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [showShareForm, setShowShareForm] = useState(false);
  const [sharedUsers, setSharedUsers] = useState<User[]>([]);
  const [selectedDocument, setSelectedDocument] = useState<Document | null>(
    null
  );
  const [isDropdownOpen, setDropdownOpen] = useState<number | null>(null);
  const [showFolderForm, setShowFolderForm] = useState(false);
  const { userInfo } = useAuthContext();
  // Custom hooks
  // Check if the user has permission to create
  const [canCreate, setCanCreate] = useState(
    useAccessControl(ActionPermissions.CREATE)
  );
  const [selectedItems, setSelectedItems] = useState<Document[]>([]);
  const checkboxRef = useRef<HTMLInputElement>(null);
  const [isDownloadModalVisible, setIsDownloadModalVisible] = useState(false);
  const [isPermanentDeleteModal, setIsPermanentDeleteModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState<SelectedTab>(
    SelectedTab.ACTIVE_DOCUMENTS
  );
  const abortController = useRef<AbortController | null>(null);
  const controller = new AbortController(); // Create a new AbortController
  abortController.current = controller;
  const [isRestoreModalVisible, setIsRestoreModalVisible] = useState(false);

  const [sortStatus, setSortStatus] = useState<DataTableSortStatus>({
    columnAccessor: 'name',
    direction: 'asc',
  });

  const canRoleCreate = useAccessControl(ActionPermissions.CREATE);

  // Check if the user has permission to view
  const canView = useAccessControl(ActionPermissions.VIEW);

  // Check if the user has permission to share
  const canShare = useAccessControl(ActionPermissions.SHARE);

  // Check if the user has permission to edit
  const canEdit = useAccessControl(ActionPermissions.EDIT);

  // Check if the user has permission to download
  const canDownload = useAccessControl(ActionPermissions.DOWNLOAD);

  // Check if the user has permission to delete
  const canDelete = useAccessControl(ActionPermissions.DELETE);

  const { isMobile } = useTheme();
  const {
    getDocumentList,
    loading,
    deleteDocument,
    multipleDocumentsDownload,
    multipleFoldersDownload,
    downloadStatuses,
    fetchDownloadStatuses,
    restoreMultipleUsers,
    deleteMultipleDocuments,
    getDeletedDocuments,
  } = useDocumentService();
  const { debounceVal, setCurrentValue } = useDebounce(null, 300);
  const {
    documents: data,
    setDocuments,
    otherDetails: folderDetail,
  } = useDocumentContext();
  const { movedFolder, setRefreshFolderByParentId, setRefreshRootFolders } =
    useAppContext();
  const navigate = useNavigate();
  const lastElementRef = useRef<HTMLDivElement | null>(null);
  const [selectedFilter, setSelectedFilter] = useState(DocType.All);
  const searchInputRef = useRef<HTMLInputElement>(null); // Create a ref for the input field
  const [searchFilter, setSearchFilter] = useState(SearchType.Global);

  // Check if a file is previewable
  const isPreviewable = (fileName: string): boolean => {
    const extension = fileName.split('.').pop()?.toLowerCase();
    return Object.values(PreviewExtensions).includes(
      extension as PreviewExtensions
    );
  };

  const handleAction = (item: Document, action: string) => {
    setSelectedDocument(item);
    setDropdownOpen(null); // Close the dropdown if it's already open
    console.log(`${action} action triggered`);
    // Implement the action handling logic here
    switch (action) {
      case 'rename':
        setShowFolderForm(true);
        break;
      case 'delete':
        setIsDeleteModalVisible(true);
        break;
      case 'download':
        // Check if item is a folder
        if (item.isFolder) {
          // Handle folder download
          if (!userInfo?.id) {
            console.error('User ID is required but missing.');
            return; // Stop execution
          }
          multipleFoldersDownload([item.id], userInfo.id);
        } else {
          // Handle single file download
          multipleDocumentsDownload([item.id]);
        }
        break;
      case 'share':
        setShowShareForm(true);
        break;
      case 'view':
        // Clear the search input when navigating
        setCurrentValue(null); // Reset the search input value
        resetDocumentScreen();
        if (!item.isFolder) {
          navigate(
            `${getTransformedUrl(routes.groupDocumentView, {
              groupId,
              docId: item.id,
            })}?docName=${item.name}`,
            {
              state: {
                document: item.name,
              },
            }
          );
        }
        break;
      case 'restore':
        setIsRestoreModalVisible(true);
        break;
      case 'delete permanent':
        setIsPermanentDeleteModal(true);
        break;
      default:
        break;
    }
  };

  // get param from route parameters
  const groupId = useParams().groupId;
  const folderId = useParams().folderId || null;

  const isSharedGroup = () => {
    return (
      !userInfo?.groupIds.includes(groupId || '') && !userInfo?.isSuperAdmin
    );
  };

  const fetchActiveDocuments = async (
    currentPage = page,
    sortingData: any = sortStatus,
    filter: DocType = selectedFilter,
    searchType: SearchType = searchFilter // Default to the current state
  ) => {
    if (!groupId) return;
    try {
      // debugger;
      // Determine the isFolder value based on the provided docType
      const docType =
        filter === DocType.Folder
          ? 'folders'
          : filter === DocType.Document
          ? 'documents'
          : 'all';

      const searchTerm = searchInputRef?.current?.value.trim() || '';
      const queryPayload: Record<string, any> = {
        page: currentPage,
        pageSize: 50,
        searchTerm,
        dateRange,
        isShared: isSharedGroup() ? true : '',
        // sorting params
        sortBy: sortingData.columnAccessor,
        sortOrder: sortingData.direction,
        docType,
      };

      // Only include isLocalSearch if searchType is 'local' and searchTerm is not empty
      if (searchType === SearchType.Local && searchTerm) {
        queryPayload.isLocalSearch = true;
      } else {
        delete queryPayload.isLocalSearch;
      }

      const documents =
        (await getDocumentList(
          groupId,
          folderId,
          queryPayload,
          abortController.current?.signal
        )) || [];

      if (currentPage === 1) {
        setDocuments(documents);
      } else {
        setDocuments([...data, ...documents]);
      }

      // Only increment the page if we fetched a full batch
      if (documents.length === 50) {
        setPage(currentPage + 1);
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled:', error.message);
      } else {
        console.error(error);
      }
    }
  };

  const fetchDeletedDocuments = async () => {
    if (!groupId) return;
    try {
      const searchTerm = searchInputRef?.current?.value.trim() || '';

      const queryPayload: Record<string, any> = {
        searchTerm,
        isShared: isSharedGroup() ? true : '',
        sortBy: sortStatus.columnAccessor,
        sortOrder: sortStatus.direction,
        isLocalSearch: true, // Deleted documents should only support local search
      };

      const documents =
        (await getDeletedDocuments(
          groupId,
          queryPayload,
          abortController.current?.signal
        )) ?? [];

      setDocuments(documents);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled:', error.message);
      } else {
        console.error(error);
      }
    }
  };

  // Wrapper function to determine which fetch function to call
  const fetchData = (
    currentPage = page,
    sortingData: any = sortStatus,
    filter: DocType = selectedFilter,
    searchType: SearchType = searchFilter
  ) => {
    if (selectedTab === SelectedTab.ACTIVE_DOCUMENTS) {
      fetchActiveDocuments(currentPage, sortingData, filter, searchType);
    } else {
      fetchDeletedDocuments();
    }
  };

  // Handle filter changes
  const handleFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value as DocType;
    setSelectedFilter(selectedValue);

    // Reset the page and fetch data for the new filter
    setPage(1);
    fetchData(1, sortStatus, selectedValue);
  };

  const handleSearchFilter = (selectedValue: SearchType) => {
    setSearchFilter(selectedValue);
    setPage(1);
    fetchData(1, sortStatus, selectedFilter, selectedValue);
  };

  const SearchOptions = [
    { value: 'global', label: 'Global Search' },
    { value: 'local', label: 'Local Search' },
  ];

  // Use the hook
  useInfiniteScroll(lastElementRef, { threshold: 0.5 }, () => {
    if (data.length % 50 === 0) {
      // Ensure we don't fetch if the last batch wasn't full
      fetchData(page);
    }
  });

  useEffect(() => {
    if (userInfo?.isSuperAdmin) return;
    setCanCreate(false);
    if (isSharedGroup() && !folderId) {
      setCanCreate(false);
    }

    if (isSharedGroup() && folderId && isSharedCreate()) {
      setCanCreate(true);
    }
    if (canRoleCreate && !isSharedGroup()) {
      setCanCreate(true);
    }

    if (folderId && userInfo?.id === folderDetail?.userId) {
      setCanCreate(true);
    }
  }, [folderDetail]);

  useEffect(() => {
    resetDocumentScreen();
    fetchData(1);
  }, [debounceVal, folderId, dateRange, selectedTab]);

  useEffect(() => {
    if (!movedFolder) return;
    setDocuments(data.filter(doc => doc.id !== movedFolder.id));
  }, [movedFolder]);

  const loadMoreRecords = () => {
    if (data.length % 50 === 0 && !loading) {
      // Ensure we don't fetch if the last batch wasn't full
      fetchData(page);
    }
  };

  const handleDeleteDocument = async () => {
    setIsDeleteModalVisible(false);
    if (!selectedDocument?.id) return;
    try {
      await deleteDocument(selectedDocument.id);
      setDocuments(data.filter(item => item.id !== selectedDocument.id));
      if (selectedDocument.parentId)
        setRefreshFolderByParentId(selectedDocument.parentId);
      setRefreshRootFolders(true);
      setSelectedDocument(null);
      showToast(
        'success',
        `${
          selectedDocument.isFolder ? 'Folder' : 'Document'
        } Deleted Successfully.`
      );
    } catch (error: any) {
      console.error(error);
      showToast('error', error.response.data.message);
    } finally {
      setDropdownOpen(null); // Close the dropdown if it's already open
    }
  };

  const handleMultipleDownload = async () => {
    setIsDownloadModalVisible(false);

    const folderIds: string[] = [];
    const fileIds: string[] = [];

    // Categorize the selected items into folders and files
    selectedItems.forEach((document: Document) => {
      if (document.isFolder) {
        folderIds.push(document.id);
      } else {
        fileIds.push(document.id);
      }
    });

    if (!userInfo?.id) {
      console.error('User ID is required but missing.');
      return; // Stop execution
    }
    // Download folders and files based on the selected items
    if (folderIds.length > 0) {
      await multipleFoldersDownload(folderIds, userInfo.id);
    }
    if (fileIds.length > 0) {
      await multipleDocumentsDownload(fileIds);
    }

    // Optionally, reset selection after download
    setSelectedItems([]);
  };

  useEffect(() => {
    const folderIds: string[] = [];

    // Separate folders and files
    data.forEach((document: Document) => {
      if (document.isFolder) {
        folderIds.push(document.id); // Add folder IDs
      }
    });

    // Call the function to fetch statuses based on folder IDs
    if (folderIds.length > 0) {
      fetchDownloadStatuses(userInfo?.id || '', folderIds);
    }
  }, [data]);

  const handleMultipleDelete = async () => {
    setIsPermanentDeleteModal(false); // Close modal after deletion
    let selectedIds: string[] = [];

    if (selectedDocument?.id) {
      selectedIds = [selectedDocument.id]; // Use selectedDocument.id if it's valid
    } else {
      selectedIds = selectedItems.map(item => item.id); // Use selectedItems if selectedDocument.id is not valid
    }

    if (selectedIds.length === 0) {
      console.warn('No items selected for deletion');
      return;
    }

    try {
      await deleteMultipleDocuments(selectedIds); // Call delete function
      setDocuments(data.filter(item => !selectedIds.includes(item.id))); // Remove deleted items from state
      setSelectedItems([]); // Clear selection
      const itemLabel = selectedIds.length > 1 ? 'documents' : 'document'; // Use plural for multiple IDs
      showToast(
        'success',
        `Successfully deleted ${selectedIds.length} ${itemLabel}.` // Dynamic toast message based on the number of items
      );
    } catch (error) {
      console.error('Error deleting documents:', error);
    }
  };

  const handleRestore = async () => {
    setIsRestoreModalVisible(false);
    let selectedIds: string[] = [];

    if (selectedDocument?.id) {
      selectedIds = [selectedDocument.id]; // Prioritize selectedDocument.id
    } else {
      selectedIds = selectedItems.map(item => item.id); // Otherwise, use selectedItems
    }

    if (selectedIds.length === 0) {
      console.warn('No items selected for restore');
      return;
    }

    try {
      await restoreMultipleUsers(selectedIds);
      setDocuments(data.filter(item => !selectedIds.includes(item.id))); // Remove restored items from state
      setSelectedDocument(null);
      setSelectedItems([]);
      const itemLabel = selectedIds.length > 1 ? 'documents' : 'document'; // Use plural for multiple IDs
      showToast(
        'success',
        `Successfully restored ${selectedIds.length} ${itemLabel}.` // Dynamic toast message based on the number of items
      );
    } catch (error) {
      console.error('Error restoring users:', error);
    }
  };

  const columns: ColomnProps[] = [
    {
      key: 'name',
      title: 'FOLDERS/DOCUMENTS',
      textAlign: 'left',
      sortable: selectedTab === SelectedTab.ACTIVE_DOCUMENTS,
    },
    {
      key: 'status',
      title: 'STATUS',
      textAlign: 'left',
    },
    {
      key: 'createdAt',
      title: 'CREATED DATE',
      textAlign: 'left',
      sortable: selectedTab === SelectedTab.ACTIVE_DOCUMENTS,
    },
    {
      key: 'updatedAt',
      title: 'LAST MODIFIED DATE',
      textAlign: 'left',
      sortable: selectedTab === SelectedTab.ACTIVE_DOCUMENTS,
    },
    { key: 'createdBy', title: 'CREATED BY', textAlign: 'left' },
    // { key: 'description', title: 'DESCRIPTION', textAlign: 'left' },
    { key: 'action', title: 'Action', textAlign: 'left' },
  ];

  const filteredColumns =
    selectedTab === SelectedTab.DELETED_DOCUMENTS
      ? columns.filter(
          column => column.key !== 'status' && column.key !== 'createdBy'
        ) // Remove status and createdBy column for Deleted Documents
      : columns;

  const isSharedCreate = () => {
    return (
      isSharedGroup() &&
      folderDetail?.DocumentShare?.[0]?.action === DocSharePermissions.EDITOR &&
      (canRoleCreate || folderDetail?.userId === userInfo?.id)
    );
  };

  const getActionMenuTemplate = (item: Document, status: string) => {
    const canEditDocument = canEdit || item.userId === userInfo?.id;
    const canShareDocument = canShare || item.userId === userInfo?.id;
    const canDeleteDocument = canDelete || item.userId === userInfo?.id;
    const canDownloadDocument =
      canDownload || item.userId === userInfo?.id || canView;

    // Prepare action menus based on permissions
    // const isSharedEdit =
    //   !isSharedGroup() ||
    //   item.userId === userInfo?.id ||
    //   (item?.DocumentShare?.[0]?.action === DocSharePermissions.EDITOR &&
    //     item?.DocumentShare?.[0]?.documentId === item.id);

    // const isShareDelete =
    //   !isSharedGroup() || (isSharedEdit && item.userId === userInfo?.id);

    const isSharedEdit =
      !isSharedGroup() ||
      (isSharedGroup() &&
        item?.DocumentShare?.[0]?.action === DocSharePermissions.EDITOR &&
        canEditDocument);

    const isSharedAllowed =
      !isSharedGroup() ||
      (isSharedGroup() &&
        item?.DocumentShare?.[0]?.action === DocSharePermissions.EDITOR);

    const isSharedDownload =
      !isSharedGroup() ||
      (isSharedGroup() && item?.DocumentShare?.[0]?.documentId);

    const selectableRecords =
      !status || status === 'completed' || status === 'error';

    const isDeletedTab = selectedTab === SelectedTab.DELETED_DOCUMENTS;

    const actionMenus = [
      {
        label: 'Rename',
        action: () => handleAction(item, 'rename'), // Pass a function instead of a string
        allow: canEditDocument && isSharedEdit && !isDeletedTab,
      },
      {
        label: 'Download',
        action: () => handleAction(item, 'download'), // Function
        allow:
          selectableRecords &&
          canDownloadDocument &&
          isSharedDownload &&
          !isDeletedTab,
      },
      {
        label: 'Delete',
        action: () => handleAction(item, 'delete'), // Function
        allow:
          canDeleteDocument &&
          isSharedAllowed &&
          !isDeletedTab &&
          selectableRecords,
      },
      {
        label: 'Share',
        action: () => handleAction(item, 'share'), // Function
        allow: canShareDocument && isSharedAllowed && !isDeletedTab,
      },
      {
        label: 'View',
        action: () => handleAction(item, 'view'), // Function
        allow:
          canView &&
          !item.isFolder &&
          isPreviewable(item.name) &&
          !isDeletedTab,
      },
      {
        label: 'Delete',
        action: () => handleAction(item, 'delete permanent'),
        allow: isDeletedTab,
      },
      {
        label: 'Restore',
        action: () => handleAction(item, 'restore'),
        allow: isDeletedTab,
      },
    ];

    // Filter allowed actions
    const allowedActions = actionMenus.filter(menu => menu.allow);
    return allowedActions;
  };

  const navigateToFolderAndDocs = (folder: Document) => {
    // clearSearchInput(); // Clear the search input when filter changes
    if (searchInputRef.current) {
      searchInputRef.current.value = ''; // Clear the input field value
    }
    setCurrentValue(''); // Clear the state value
    if (!canView && !folder.isFolder) return;
    if (selectedTab === SelectedTab.DELETED_DOCUMENTS) return;
    if (folder.isFolder) {
      navigate(
        getTransformedUrl(routes.groupFoldersDocuments, {
          groupId,
          folderId: folder.id,
        }),
        {
          state: {
            folderName: folder.name,
          },
        }
      );
    } else if (isPreviewable(folder.name)) {
      // Navigate to Document View for previewable files
      navigate(
        `${getTransformedUrl(routes.groupDocumentView, {
          groupId,
          docId: folder.id,
        })}?docName=${folder.name}`,
        {
          state: {
            document: folder.name,
          },
        }
      );
    } else {
      // Download non-previewable files
      multipleDocumentsDownload([folder.id]);
    }
  };

  const clearSearchInput = () => {
    if (searchInputRef.current) {
      searchInputRef.current.value = ''; // Clear the input field value
    }
    setCurrentValue(''); // Clear the state value

    if (selectedTab === SelectedTab.DELETED_DOCUMENTS) {
      fetchDeletedDocuments(); // Refetch deleted documents when clearing search
    } else {
      fetchActiveDocuments(); // Refetch active documents when clearing search
    }
  };

  const resetDocumentScreen = () => {
    setPage(1);
    setDocuments([]);
  };

  const getDocumentIcon = (fileName: any) => {
    const extension = fileName.split('.').pop().toLowerCase();
    switch (extension) {
      case 'pdf':
        return pdfIcon;
      case 'doc':
      case 'docx':
        return wordIcon;
      case 'xls':
      case 'xlsx':
      case 'xlsm':
        return excelIcon;
      case 'zip':
        return zipIcon;
      case 'jpg':
      case 'jpeg':
        return jpgIcon;
      case 'exe':
        return exeIcon;
      case 'msg':
        return msgIcon;
      case 'tif':
        return tifIcon;
      default:
        return document;
    }
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'queued':
        return 'bg-yellow-200';
      default:
        return 'bg-none';
    }
  };

  const handleDownload = (url: string) => {
    window.open(url, '_blank');
  };

  const isAllSelected =
    selectedItems.length === data?.length && data?.length > 0;
  const isSomeSelected = selectedItems.length > 0 && !isAllSelected;

  const handleCheckboxChange = (item: Document) => {
    setSelectedItems(prevSelected =>
      prevSelected.includes(item)
        ? prevSelected.filter(i => i !== item)
        : [...prevSelected, item]
    );
  };

  const handleSelectAll = () => {
    if (isAllSelected) {
      setSelectedItems([]); // Deselect all
    } else {
      setSelectedItems(data || []); // Select all
    }
  };

  useEffect(() => {
    if (checkboxRef.current) {
      checkboxRef.current.indeterminate = isSomeSelected;
    }
  }, [isSomeSelected]);

  const handleTabChange = async (tab: string) => {
    if (abortController) {
      abortController.current?.abort(); // Cancel the previous request
    }

    setSelectedTab(tab as SelectedTab);
    setSelectedItems([]);
  };

  // for admin role assigned to user
  const hasAdminRole = userInfo?.roles.find(
    role => role.name.toLowerCase() === 'admin'
  );

  const handleDragStart = (e: React.DragEvent, folder: Document) => {
    const dragData = folder;
    e.dataTransfer.setData('folder', JSON.stringify(dragData));
  };

  const tableData = data?.map((item, index) => ({
    id: item.id,
    isFolder: item.isFolder,
    docName: item.name,
    docStatus: downloadStatuses[item.id]?.status,
    name: (
      <div
        draggable={canEdit && selectedTab === SelectedTab.ACTIVE_DOCUMENTS}
        onDragStart={event => handleDragStart(event, item)}
        className="flex items-center gap-x-3 py-2"
      >
        <div
          className={`flex flex-row gap-3 ml-0 justify-left ${
            canView || item.isFolder ? 'cursor-pointer' : ''
          }`}
          onClick={() => navigateToFolderAndDocs(item)}
        >
          <img
            draggable={canEdit && selectedTab === SelectedTab.ACTIVE_DOCUMENTS}
            src={item.isFolder ? folder : getDocumentIcon(item.name)}
            alt={item.isFolder ? 'Folder Icon' : 'Document Icon'}
            className="w-8 h-8"
          />
          <span
            key={index}
            title={item.name}
            className="whitespace-nowrap truncate break-words mt-2 font-inter font-medium leading-[19.36px] text-left text-semiBase lg:text-base"
            style={{ maxWidth: '250px' }}
          >
            {item.name}
          </span>
        </div>
      </div>
    ),
    status: (
      <div className="flex items-center gap-x-3 py-2">
        <span
          className={`font-inter font-medium leading-[19.36px] text-left text-semiBase lg:text-base p-2 rounded ${getStatusColor(
            downloadStatuses[item.id]?.status
          )}`}
        >
          {downloadStatuses[item.id]?.status === 'completed' &&
          downloadStatuses[item.id]?.zipFileName ? (
            <Button
              onClick={() => {
                const zipFileName = downloadStatuses[item.id]?.zipFileName;
                if (zipFileName) {
                  handleDownload(zipFileName);
                } else {
                  console.warn('No zipFileName available for download');
                }
              }}
              className="bg-primary-lightBlue px-2 rounded-lg border focus:z-10 focus:ring-4 focus:ring-gray-200 border-gray-200 text-white font-bold py-2 inline-flex items-center"
            >
              <svg
                className="w-[18px] h-[18px] text-white mr-1"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.8"
                  d="M4 15v2a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3v-2m-8 1V4m0 12-4-4m4 4 4-4"
                />
              </svg>
              <span className="text-semiBase">Download</span>
            </Button>
          ) : downloadStatuses[item.id]?.status === 'error' ? (
            <Button
              onClick={() =>
                multipleFoldersDownload([item.id], userInfo?.id || '')
              }
              className="bg-red-500 px-2 rounded-lg border focus:z-10 focus:ring-4 focus:ring-gray-200 border-gray-200 text-white font-bold py-2 inline-flex items-center"
            >
              <svg
                className="w-[18px] h-[18px] text-white mr-1"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.8"
                  d="M12 5v7m0 4h.01M3 12a9 9 0 1 1 18 0 9 9 0 0 1-18 0Z"
                />
              </svg>
              <span className="text-semiBase">Resume</span>
            </Button>
          ) : (
            downloadStatuses[item.id]?.status || '-'
          )}
        </span>
      </div>
    ),
    createdAt: (
      <div className="flex items-center gap-x-3 py-2">
        <span className="font-inter font-medium leading-[19.36px] text-left text-semiBase lg:text-base">
          {dayjs(item.createdAt).format('MMMM D, YYYY h:mm A')}
        </span>
      </div>
    ),
    updatedAt: (
      <div className="flex items-center gap-x-3 py-2">
        <span className="font-inter font-medium leading-[19.36px] text-left text-semiBase lg:text-base">
          {dayjs(item.updatedAt).format('MMMM D, YYYY h:mm A')}
        </span>
      </div>
    ),
    createdBy: (
      <div className="flex items-center gap-x-3 py-2">
        <span className="font-inter font-medium leading-[19.36px] text-left text-semiBase lg:text-base">
          {getUserFullName(item.user)}
        </span>
      </div>
    ),
    description: (
      <div className="flex items-center gap-x-3 py-2">
        <span className="font-inter font-medium leading-[19.36px] text-left text-semiBase lg:text-base">
          {item.description}
        </span>
      </div>
    ),
    action:
      (getActionMenuTemplate(item, downloadStatuses[item.id]?.status).length >
        0 && (
        <CustomDropdown
          trigger={
            <span className="py-2 text-center cursor-pointer">
              <img src={dotAction} alt="Dot Action" className="w-4 h-4" />
            </span>
          }
          items={getActionMenuTemplate(item, downloadStatuses[item.id]?.status)}
        />
      )) ||
      '',
  }));

  // get action menue template

  const handleAddFolder = () => {
    setShowFolderForm(true);
    setSelectedDocument(null);
  };

  const handleAddDocument = () => {
    // setShowDocUploadForm(true);
    // navigate to upload document route
    navigate(
      getTransformedUrl(
        folderId ? routes.uploadFolderDocuments : routes.uploadGroupDocuments,
        {
          groupId,
          folderId: folderId,
        }
      ),
      {}
    );
  };

  useEffect(() => {
    setDropdownOpen(null);
  }, [data]);

  const handleSearchDocument = (searchText: string) => {
    resetDocumentScreen();
    // fetch data with search term
    if (searchText.trim() === '') {
      // If search is cleared, reset data
      setCurrentValue(''); // Replace with your logic to fetch default docs
    } else {
      // Fetch data with the search term
      setCurrentValue(searchText); // Replace with your logic to fetch search results
    }
  };

  const handleSearchDeleteDocuments = (searchText: string) => {
    resetDocumentScreen();

    if (searchText.trim() === '') {
      clearSearchInput(); // Call the function to reset search
      return;
    }

    // Filter the documents based on the search text
    const filtered = data.filter(doc =>
      doc.name.toLowerCase().includes(searchText.toLowerCase())
    );

    setDocuments(filtered); // Update filtered documents
  };

  const handleDateRangeChange = (selectedDateRange: DateValueType) => {
    const start = selectedDateRange?.startDate
      ? new Date(selectedDateRange.startDate)
      : null;
    const end = selectedDateRange?.endDate
      ? new Date(new Date(selectedDateRange.endDate).setHours(23, 59, 59, 999))
      : null;

    setDateRange({
      start: start,
      end: end,
    });
  };

  const getDocumentDeleteMsg = (document: Document) => {
    return (
      <div>
        <p>This action cannot be undone.</p>
        <p>{`Are you sure you want to delete this ${
          document?.isFolder ? 'Folder' : 'Document'
        }`}</p>
      </div>
    );
  };

  const handleSort = (data: DataTableSortStatus) => {
    setSortStatus(data);
    fetchData(1, data);
  };

  const filterOptions = [
    { value: 'All', label: 'All' },
    { value: 'folders', label: 'Folders' },
    { value: 'documents', label: 'Documents' },
  ];

  return (
    <>
      <div className="max-w-screen-xl mx-auto px-4 py-1 container">
        <div className="w-full overflow-x-auto mb-2">
          <div className="inline-block min-w-full whitespace-nowrap">
            <BreadcrumbNav className="font-inter text-sm font-bold text-gray-500 leading-[15px] text-left mb-4 whitespace-nowrap" />
          </div>
        </div>
        {(hasAdminRole || userInfo?.isSuperAdmin) && !isMobile && (
          <div
            className={`relative flex gap-6 border-b border-gray-200 mx-0 my-3`}
          >
            <div
              className={`relative cursor-pointer text-base font-bold pb-2 ${
                selectedTab === SelectedTab.ACTIVE_DOCUMENTS
                  ? 'text-primary-lightBlue'
                  : 'text-gray-500'
              }`}
              onClick={() => handleTabChange(SelectedTab.ACTIVE_DOCUMENTS)}
            >
              Active Documents
              <div
                className={`absolute bottom-0 left-0 w-full h-[3px] rounded-full transition-all duration-300 ${
                  selectedTab === SelectedTab.ACTIVE_DOCUMENTS
                    ? 'bg-primary-lightBlue'
                    : 'bg-transparent'
                }`}
              ></div>
            </div>
            <div
              className={`relative cursor-pointer text-base font-bold pb-2 ${
                selectedTab === SelectedTab.DELETED_DOCUMENTS
                  ? 'text-primary-lightBlue'
                  : 'text-gray-500'
              }`}
              onClick={() => handleTabChange(SelectedTab.DELETED_DOCUMENTS)}
            >
              Deleted Documents
              <div
                className={`absolute bottom-0 left-0 w-full h-[3px] rounded-full transition-all duration-300 ${
                  selectedTab === SelectedTab.DELETED_DOCUMENTS
                    ? 'bg-primary-lightBlue'
                    : 'bg-transparent'
                }`}
              ></div>
            </div>
          </div>
        )}
        <div className="items-center justify-between md:flex">
          <div className="max-w-lg flex items-center justify-between">
            <div
              className={`${
                selectedItems.length > 0 && 'items-center mb-11'
              } flex flex-col`}
            >
              <div className="justify-start">
                <h3 className="font-inter text-2xl font-semibold leading-[29.26px] text-left mb-8 md:mb-2">
                  Documents
                </h3>
                {!isMobile && selectedItems.length > 0 && (
                  <>
                    {selectedTab === SelectedTab.DELETED_DOCUMENTS ? (
                      <div className="flex gap-3">
                        {/* Delete Button */}
                        <Button
                          onClick={() => setIsPermanentDeleteModal(true)}
                          className="bg-brandRed px-7 rounded-lg border focus:z-10 focus:ring-4 focus:ring-gray-200 border-gray-200 text-white font-bold py-[0.72rem] inline-flex items-center"
                        >
                          <svg
                            className="w-[24px] h-[24px] text-white mr-1"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2.2"
                              d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z"
                            />
                          </svg>
                          <span className="text-base">Delete</span>
                        </Button>
                      </div>
                    ) : (
                      <Button
                        onClick={() => setIsDownloadModalVisible(true)}
                        className="bg-primary-lightBlue px-4 rounded-lg border focus:z-10 focus:ring-4 focus:ring-gray-200 border-gray-200 text-white font-bold py-[0.72rem] inline-flex items-center"
                      >
                        <svg
                          className="w-[24px] h-[24px] text-white mr-1"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.8"
                            d="M4 15v2a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3v-2m-8 1V4m0 12-4-4m4 4 4-4"
                          />
                        </svg>
                        <span className="text-base">Download</span>
                      </Button>
                    )}
                  </>
                )}
              </div>
            </div>
            {!isMobile && selectedTab === SelectedTab.ACTIVE_DOCUMENTS && (
              <CommonSelect
                options={filterOptions}
                value={selectedFilter}
                onChange={handleFilter}
                className="appearance-none bg-white border border-gray-300 text-black text-base font-medium py-3 pr-8 pl-2 rounded-full md:rounded-xl shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 w-full md:w-auto"
                wrapperClass="ml-3 mb-2 w-auto"
              />
            )}

            {isMobile &&
              canCreate &&
              (!isSharedGroup() || isSharedCreate()) &&
              selectedTab === SelectedTab.ACTIVE_DOCUMENTS && (
                <div>
                  <CustomDropdown
                    trigger={
                      <span className="py-2 text-center cursor-pointer">
                        <ActionBtn
                          placement="bottom-end"
                          btnClassName="py-2 px-8 mb-8 text-semiBase md:text-xl font-bold
                text-white focus:outline-none rounded-lg border
                border-gray-200 bg-primary-lightBlue hover:bg-primary-lightBlue focus:z-10
                focus:ring-4 focus:ring-gray-200 text-center"
                          button={<>+ Add</>}
                        />
                      </span>
                    }
                    items={[
                      {
                        label: 'Add Folder',
                        action: handleAddFolder,
                      },
                      {
                        label: 'Upload Document',
                        action: handleAddDocument,
                      },
                    ]}
                  />
                </div>
              )}
          </div>

          <div className="flex items-center flex-col md:flex-row">
            {/* Show the search box only for Deleted Documents */}
            {selectedTab === SelectedTab.DELETED_DOCUMENTS && (
              <div className="relative flex items-center w-full md:w-auto mr-0 lg:mr-4 mb-2">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <img src={`${searchImg}`} alt="Search image" className="" />
                </div>
                <input
                  ref={searchInputRef}
                  type="search"
                  id="default-search"
                  className="block w-full text-[15px] font-normal text-left py-3 pl-10 px-6 text-gray-900 border
        border-gray-300 rounded-xl bg-white focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Search Deleted Docs"
                  onChange={e => handleSearchDeleteDocuments(e.target.value)}
                />
              </div>
            )}
            {selectedTab === SelectedTab.ACTIVE_DOCUMENTS && (
              <>
                <CommonSelect
                  options={SearchOptions}
                  value={searchFilter}
                  onChange={e =>
                    handleSearchFilter(e.target.value as SearchType)
                  }
                  className="appearance-none bg-white border border-gray-300 text-black text-base font-medium py-3 pr-8 pl-2 rounded-xl shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 w-full md:w-auto"
                  wrapperClass="mr-0 md:mr-2 mb-2 w-full md:w-auto"
                />

                <div className="relative flex items-center w-full md:w-auto mr-0 lg:mr-4 mb-2">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <img src={`${searchImg}`} alt="Search image" className="" />
                  </div>
                  <input
                    ref={searchInputRef}
                    type="search"
                    id="default-search"
                    className="block w-full text-[15px] font-normal text-left py-3 pl-10 px-6 text-gray-900 border
        border-gray-300 rounded-xl bg-white focus:ring-blue-500 focus:border-blue-500"
                    placeholder={
                      searchFilter === 'global'
                        ? 'Search Global Docs'
                        : 'Search Local Docs'
                    }
                    onChange={e => handleSearchDocument(e.target.value)}
                  />
                </div>
              </>
            )}
            {/* // date range filter */}
            {!isMobile && selectedTab === SelectedTab.ACTIVE_DOCUMENTS && (
              <>
                <div className="sm:w-60 w-full h-12 mr-1 lg:mr-3 mb-1 lg:mb-3">
                  <DateRange onDateSelect={handleDateRangeChange} />
                </div>
                {canCreate && (!isSharedGroup() || isSharedCreate()) && (
                  <div>
                    <CustomDropdown
                      trigger={
                        <span className="py-2 text-center cursor-pointer">
                          <ActionBtn
                            placement="bottom-end"
                            btnClassName="dropdown-toggle py-2 px-10 mr-2 mb-2 text-semiBase md:text-xl font-normal
        text-white focus:outline-none rounded-xl border
        border-gray-200 bg-primary-lightBlue hover:bg-blue-500 focus:z-10
        focus:ring-4 focus:ring-gray-200 text-center whitespace-nowrap"
                            button={<>+ Add</>}
                          />
                        </span>
                      }
                      items={[
                        {
                          label: 'Add Folder',
                          action: handleAddFolder,
                        },
                        {
                          label: 'Upload Document',
                          action: handleAddDocument,
                        },
                      ]}
                    />
                  </div>
                )}
              </>
            )}
          </div>
          {isMobile && selectedTab === SelectedTab.ACTIVE_DOCUMENTS && (
            <div className="w-full border rounded-xl">
              <DateRange onDateSelect={handleDateRangeChange} />
            </div>
          )}

          {isMobile && selectedTab === SelectedTab.ACTIVE_DOCUMENTS && (
            <CommonSelect
              options={filterOptions}
              value={selectedFilter}
              onChange={handleFilter}
              className="appearance-none bg-white border border-gray-300 text-black text-base font-medium py-2 pr-10 pl-2 rounded-xl shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 w-full"
              wrapperClass="w-full md:w-auto mr-0 lg:mr-4 mb-2 mt-2"
            />
          )}

          {isMobile && (
            <div className="flex w-full mt-1 space-x-2">
              {/* Sort By Dropdown */}
              <SortingDropdown
                label="Sort By"
                value={sortStatus.columnAccessor}
                options={[
                  { value: 'name', label: 'Name' },
                  { value: 'createdAt', label: 'Date' },
                  { value: 'updatedAt', label: 'Last Modified' },
                ]}
                onChange={selectedColumn => {
                  // When Sort By changes, set direction to 'asc'
                  handleSort({
                    columnAccessor: selectedColumn,
                    direction: 'asc',
                  });
                }}
              />

              {/* Sort Order Dropdown */}
              <SortingDropdown
                label="Sort Order"
                value={sortStatus.direction}
                options={[
                  { value: 'asc', label: 'Ascending' },
                  { value: 'desc', label: 'Descending' },
                ]}
                onChange={selectedOrder => {
                  // Ensure the direction is correctly typed
                  handleSort({
                    columnAccessor: sortStatus.columnAccessor,
                    direction: selectedOrder as 'asc' | 'desc',
                  });
                }}
              />
            </div>
          )}
        </div>

        <div
          className={`shadow-md border rounded-2xl overflow-x-auto bg-white mt-4 ${
            selectedItems.length > 0 && 'sm:mt-[-1.4rem]'
          } sm:mt-2`}
        >
          {isMobile ? (
            <div className="flex flex-col">
              {loading ? ( // Conditionally show loader when loading is true
                <div className="flex justify-center items-center py-4">
                  {<DataSaveLoader visible={true} />}
                </div>
              ) : data.length === 0 ? ( // Check if there is no data
                <div className="flex justify-center items-center py-16">
                  <div>
                    <img
                      src={`${noRecords}`}
                      className="w-16 h-16"
                      alt="Empty state"
                    />
                    <p className="text-gray-500">No records</p>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="flex items-center justify-between px-6 p-4">
                    <div className="flex items-center gap-2">
                      <input
                        ref={checkboxRef}
                        type="checkbox"
                        checked={isAllSelected}
                        onChange={handleSelectAll}
                        className="cursor-pointer w-4 h-4"
                      />
                      <span className="text-semiBase font-bold text-gray-500 ml-1">
                        Select All
                      </span>
                    </div>
                    {isMobile && selectedItems.length > 0 && (
                      <>
                        {/* Delete Button (only for Deleted Documents tab) */}
                        {selectedTab === SelectedTab.DELETED_DOCUMENTS && (
                          <div className="space-x-2">
                            <Button
                              onClick={() => setIsPermanentDeleteModal(true)}
                              className="ml-1 bg-brandRed hover:bg-brandRed rounded-lg border focus:z-10 
                   focus:ring-4 focus:ring-gray-200 border-gray-200 text-white font-bold py-2 px-4 inline-flex items-center"
                              title="Delete"
                            >
                              <svg
                                className="w-[18px] h-[18px] mr-1 text-white"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2.2"
                                  d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z"
                                />
                              </svg>
                              <span className="text-sm">Delete</span>
                            </Button>
                          </div>
                        )}

                        {/* Download Button (only if not in Deleted Documents tab) */}
                        {selectedTab !== SelectedTab.DELETED_DOCUMENTS && (
                          <Button
                            onClick={() => setIsDownloadModalVisible(true)}
                            className="ml-1 bg-primary-lightBlue hover:bg-primary-lightBlue rounded-lg border focus:z-10 
                   focus:ring-4 focus:ring-gray-200 border-gray-200 text-white font-bold py-2 px-4 inline-flex items-center"
                            title="Download"
                          >
                            <svg
                              className="w-[18px] h-[18px] mr-1 text-white"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.8"
                                d="M4 15v2a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3v-2m-8 1V4m0 12-4-4m4 4 4-4"
                              />
                            </svg>
                            <span className="text-sm">Download</span>
                          </Button>
                        )}
                      </>
                    )}
                  </div>
                  {(hasAdminRole || userInfo?.isSuperAdmin) && isMobile && (
                    <div className="relative flex gap-6 mx-4 my-0">
                      <div
                        className={`relative cursor-pointer text-semiBase font-bold pb-2 ${
                          selectedTab === SelectedTab.ACTIVE_DOCUMENTS
                            ? 'text-primary-lightBlue'
                            : 'text-gray-500'
                        }`}
                        onClick={() =>
                          handleTabChange(SelectedTab.ACTIVE_DOCUMENTS)
                        }
                      >
                        Active Documents
                        <div
                          className={`absolute bottom-0 left-0 w-full h-[2px] rounded-full transition-all duration-300 ${
                            selectedTab === SelectedTab.ACTIVE_DOCUMENTS
                              ? 'bg-primary-lightBlue'
                              : 'bg-transparent'
                          }`}
                        ></div>
                      </div>
                      <div
                        className={`relative cursor-pointer text-semiBase font-bold pb-2 ${
                          selectedTab === SelectedTab.DELETED_DOCUMENTS
                            ? 'text-primary-lightBlue'
                            : 'text-gray-500'
                        }`}
                        onClick={() =>
                          handleTabChange(SelectedTab.DELETED_DOCUMENTS)
                        }
                      >
                        Deleted Documents
                        <div
                          className={`absolute bottom-0 left-0 w-full h-[2px] rounded-full transition-all duration-300 ${
                            selectedTab === SelectedTab.DELETED_DOCUMENTS
                              ? 'bg-primary-lightBlue'
                              : 'bg-transparent'
                          }`}
                        ></div>
                      </div>
                    </div>
                  )}
                  <div className="flex flex-col md:gap-4">
                    {loading ? ( // Conditionally show loader when loading is true
                      <div className="flex justify-center items-center py-4">
                        {<DataSaveLoader visible={true} />}
                      </div>
                    ) : data.length === 0 ? ( // Check if there is no data
                      <div className="flex justify-center items-center py-16">
                        <div>
                          <img
                            src={`${noRecords}`}
                            className="w-16 h-16"
                            alt="Empty state"
                          />
                          <p className="text-gray-500">No records</p>
                        </div>
                      </div>
                    ) : (
                      <div>
                        {data.map((item, idx) => (
                          <div
                            key={idx}
                            ref={
                              idx === data.length - 1 ? lastElementRef : null
                            }
                            className={`p-4 border flex flex-col ${
                              selectedItems.includes(item)
                                ? 'bg-[aliceblue]'
                                : 'bg-white'
                            }`}
                          >
                            <div className="flex items-center">
                              <div className="flex flex-col w-full gap-2 p-2">
                                <div className="flex justify-between items-center">
                                  <div
                                    className="flex items-center gap-3"
                                    onClick={() =>
                                      navigateToFolderAndDocs(item)
                                    }
                                  >
                                    <div className="flex items-center">
                                      <input
                                        type="checkbox"
                                        checked={selectedItems.includes(item)}
                                        onChange={() =>
                                          handleCheckboxChange(item)
                                        }
                                        onClick={e => e.stopPropagation()}
                                        className="cursor-pointer w-4 h-4"
                                        disabled={
                                          !!downloadStatuses[item.id]?.status &&
                                          downloadStatuses[item.id]?.status !==
                                            'completed' &&
                                          downloadStatuses[item.id]?.status !==
                                            'error'
                                        }
                                      />
                                    </div>
                                    <img
                                      src={
                                        item.isFolder
                                          ? folder
                                          : getDocumentIcon(item.name)
                                      }
                                      alt={
                                        item.isFolder
                                          ? 'Folder Icon'
                                          : 'Document Icon'
                                      }
                                      className="w-8 h-8"
                                    />
                                    <span className="text-lg font-medium break-all">
                                      {item.name}
                                    </span>
                                    {/* Status Badge */}
                                    <div className="flex items-center gap-2">
                                      <span
                                        className={`font-inter font-medium text-semiBase text-left p-2 rounded ${getStatusColor(
                                          downloadStatuses[item.id]?.status
                                        )}`}
                                      >
                                        {downloadStatuses[item.id]?.status ===
                                          'completed' &&
                                        downloadStatuses[item.id]
                                          ?.zipFileName ? (
                                          <button
                                            className="bg-primary-lightBlue text-white rounded px-2 py-1 text-sm"
                                            onClick={() => {
                                              const zipFileName =
                                                downloadStatuses[item.id]
                                                  ?.zipFileName;
                                              if (zipFileName) {
                                                handleDownload(zipFileName);
                                              } else {
                                                console.warn(
                                                  'No zipFileName available for download'
                                                );
                                              }
                                            }}
                                          >
                                            Download
                                          </button>
                                        ) : downloadStatuses[item.id]
                                            ?.status === 'error' ? (
                                          <button
                                            className="bg-red-500 text-white rounded px-2 py-1 text-sm"
                                            onClick={() =>
                                              multipleFoldersDownload(
                                                [item.id],
                                                userInfo?.id || ''
                                              )
                                            }
                                          >
                                            Resume
                                          </button>
                                        ) : (
                                          downloadStatuses[item.id]?.status ||
                                          ''
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                  {getActionMenuTemplate(
                                    item,
                                    downloadStatuses[item.id]?.status
                                  ).length > 0 && (
                                    <span className="relative cursor-pointer mr-2">
                                      <CustomDropdown
                                        trigger={
                                          <span className="py-2 text-center cursor-pointer">
                                            <img
                                              src={dotAction}
                                              alt="Dot Action"
                                              className="w-4 h-4"
                                            />
                                          </span>
                                        }
                                        items={getActionMenuTemplate(
                                          item,
                                          downloadStatuses[item.id]?.status
                                        )}
                                      />
                                    </span>
                                  )}
                                </div>
                                <div className="flex justify-between mt-2 text-black break-all">
                                  <span className="font-inter text-semiBase font-medium leading-[16.94px] text-left">
                                    <span className="text-gray-500">
                                      Created By:
                                    </span>{' '}
                                    {getUserFullName(item.user)}
                                  </span>
                                  <span className="font-inter text-semiBase font-medium ml-2 leading-[16.94px] text-right whitespace-nowrap">
                                    {dayjs(item.updatedAt).format('DD/MM/YYYY')}
                                  </span>
                                </div>
                                {/* <div className="font-inter text-semiBase font-medium leading-[16.94px] text-left mt-2">
                        <span className="text-gray-500">Description:</span>{' '}
                        {item.description}
                      </div> */}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className={`mx-auto mt-8}`}>
              <Table
                data={tableData}
                coloums={filteredColumns}
                loadMoreRecords={loadMoreRecords}
                infiniteScrolling={true}
                loadingData={!!loading}
                // sorting callback
                onSort={handleSort}
                initSortStatus={{
                  columnAccessor: 'updatedAt',
                  direction: 'desc',
                }}
                enableSelection={true}
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
                selectionCheckboxProps={{
                  color: '#316EFD',
                  style: {
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                  },
                }}
                isRecordSelectable={record =>
                  record.docStatus === 'completed' ||
                  record.docStatus === 'error' ||
                  !record.docStatus
                }
              />
            </div>
          )}
        </div>
      </div>

      {/* DeleteModal Component */}
      {selectedDocument && (
        <CommonModal
          title={`Delete ${selectedDocument?.isFolder ? 'Folder' : 'Document'}`}
          isOpen={isDeleteModalVisible}
          loading={loading}
          onClose={() => setIsDeleteModalVisible(false)}
          onDelete={handleDeleteDocument}
          body={getDocumentDeleteMsg(selectedDocument)}
          primaryBtnTxt="Delete"
        />
      )}

      {/* Download Confirmation Modal */}
      {isDownloadModalVisible &&
        selectedTab === SelectedTab.ACTIVE_DOCUMENTS && (
          <CommonModal
            title="Download Confirmation"
            isOpen={isDownloadModalVisible}
            loading={false}
            onClose={() => setIsDownloadModalVisible(false)}
            onDelete={handleMultipleDownload}
            body={`Are you sure you want to download ${selectedItems.length} ${
              selectedItems.length > 1 ? 'items' : 'item'
            }?`}
            primaryBtnTxt="Download"
          />
        )}

      {/* Delete Confirmation Modal */}
      {isPermanentDeleteModal && (
        <CommonModal
          title="Delete Confirmation"
          isOpen={isPermanentDeleteModal}
          loading={false}
          onClose={() => setIsPermanentDeleteModal(false)}
          onDelete={handleMultipleDelete}
          body={`Are you sure you want to delete ${
            selectedDocument?.id ? 1 : selectedItems.length
          } ${
            selectedDocument?.id || selectedItems.length > 1 ? 'items' : 'item'
          }?`}
          primaryBtnTxt="Delete"
        />
      )}

      {/* Restore Modal */}
      <CommonModal
        isOpen={isRestoreModalVisible}
        loading={loading}
        onClose={() => setIsRestoreModalVisible(false)}
        onDelete={handleRestore}
        title="Restore Confirmation"
        body={`Are you sure you want to restore ${
          selectedDocument?.id ? 1 : selectedItems.length
        } ${
          selectedDocument?.id || selectedItems.length > 1 ? 'items' : 'item'
        }?`}
        primaryBtnTxt="Restore"
        primaryBtnClassName="btn btn-success"
      />

      {/* Share Modal */}
      {selectedDocument && (
        <ShareModal
          isOpen={showShareForm}
          onClose={() => setShowShareForm(false)}
          document={selectedDocument}
          title={`Share "${selectedDocument.name}"`}
          loading={loading}
        />
      )}

      {showFolderForm && (
        <DocumentForm
          type={
            selectedDocument
              ? selectedDocument.isFolder
                ? 'folder'
                : 'doc'
              : 'folder'
          }
          setIsModalVisible={setShowFolderForm}
          editDocument={selectedDocument}
          setEditDocument={setSelectedDocument}
        />
      )}
    </>
  );
};
