import React, { useEffect, useRef, useState } from 'react';
import { useAppContext } from 'shared/hooks/context/useAppContext';
import { Accordian } from './Accordian';
import CommonModal from '../../../../src/components/DeleteModal';
import useDocumentService from 'shared/hooks/service/useDocumentService';
import useDebounce from 'shared/hooks/useDebounce';
import { Document } from 'shared/contexts/DocumentContext';
import { getTransformedUrl } from 'shared/utils/utils';
import { useNavigate, useParams } from 'react-router-dom';
import routes from 'shared/config/routes';
import { useTheme } from 'shared/hooks/useTheme';
import { useAuthContext } from 'shared/hooks/context/useAuthContext';

function FolderTree() {
  const navigate = useNavigate();
  const {
    activeGroup,
    childrenData,
    setMovedFolder,
    refreshRootFolders,
    setRefreshRootFolders,
    setRefreshFolderByParentId,
  } = useAppContext();
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [moveDetails, setMoveDetails] = useState<{
    folderId: string;
    newParentId: string | null;
    folderName: string;
    newParentName: string;
    movedFolderData: Document;
    oldParentId: string | null;
  } | null>(null);
  const [loading, setLoading] = useState(false);
  const { groupId, folderId } = useParams();
  const { moveDocument, getFoldersList } = useDocumentService();
  const { debounceVal, setCurrentValue } = useDebounce(null, 300);
  const searchInputRef = useRef<HTMLInputElement>(null); // Create a ref for the input field
  const [expandedGroup, setExpandedGroup] = useState<string | null>(
    groupId ?? null
  );
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const [documentList, setDocumentList] = useState<Document[]>([]);
  const data = documentList;
  const { sidebarWidth } = useTheme();
  const { userInfo } = useAuthContext();

  const isSharedGroup = () => {
    return (
      !userInfo?.groupIds.includes(groupId || '') && !userInfo?.isSuperAdmin
    );
  };

  const fetchFolder = async () => {
    if (!groupId && isSharedGroup()) return;
    setLoading(true);
    try {
      const searchTerm = searchInputRef?.current?.value.trim() || '';
      const queryPayload: Record<string, any> = {
        searchTerm,
      };
      // Call backend API to fetching the folders
      let documents = (await getFoldersList(groupId ?? '', queryPayload)) || [];
      // Sort documents alphabetically by folder name (assuming each folder has a `name` property)
      documents = documents.sort((a, b) => a.name.localeCompare(b.name));
      setDocumentList(documents);
    } catch (error) {
      console.error('Error moving folder:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isSharedGroup()) return;
    fetchFolder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId, debounceVal]);

  // Fetch root folders only when refreshRootFolders is true
  useEffect(() => {
    if (refreshRootFolders && !isSharedGroup()) {
      fetchFolder();
      setRefreshRootFolders(false); // Reset after fetching
    }
  }, [refreshRootFolders]);

  const findAllDescendants = (
    folderId: string,
    descendants: Set<string> = new Set()
  ) => {
    const childFolders = data
      .concat(childrenData)
      .filter(folder => folder.parentId === folderId);

    childFolders.forEach(folder => {
      descendants.add(folder.id);
      findAllDescendants(folder.id, descendants); // Recursive call to fetch deep children
    });

    return descendants;
  };

  const handleFolderMove = (
    folderData: Document,
    newParentId: string | null
  ) => {
    const folderId = folderData.id;
    if (folderId === newParentId) {
      alert('The Document cannot be moved to the same location.');
      return;
    }

    // Get all descendants of the folder being moved
    const allDescendants = findAllDescendants(folderId);
    // Check if the newParentId is inside the descendants of the folder
    if (allDescendants.has(newParentId as string)) {
      alert('Cannot move a folder/file into its own subfolder.');
      return;
    }

    // Check if folder is being moved to its current parent
    if (folderData?.parentId === newParentId) {
      alert('The folder/file is already in the selected location.');
      return;
    }

    const newParentFolder =
      data.find(folder => folder.id === newParentId) ||
      childrenData.find(folder => folder.id === newParentId);

    setMoveDetails({
      folderId,
      newParentId: newParentId || null,
      folderName: folderData.name || 'unknown',
      newParentName: newParentId
        ? newParentFolder?.name || 'Root'
        : activeGroup?.name || 'Root',
      movedFolderData: folderData,
      oldParentId: folderData?.parentId || null,
    });

    setIsConfirmModalVisible(true);
  };

  const confirmFolderMove = async () => {
    if (!moveDetails) return;

    const { folderId, newParentId, movedFolderData, oldParentId } = moveDetails;
    console.log(folderId, newParentId);
    setLoading(true);
    try {
      // Call backend API to move the folder
      await moveDocument(folderId, newParentId || null);
      setMovedFolder(movedFolderData);
      if (newParentId) setRefreshFolderByParentId(newParentId || '');
      setRefreshRootFolders(true);

      setIsConfirmModalVisible(false);
      setMoveDetails(null);
    } catch (error) {
      console.error('Error moving folder:', error);
    } finally {
      setLoading(false);
    }
  };

  const clearSearchInput = () => {
    setCurrentValue(''); // Clear the state
    if (searchInputRef.current) {
      searchInputRef.current.value = ''; // Clear the input field value
    }
    setCurrentValue(''); // Clear the state value
    fetchFolder();
  };

  const handleSearchDocument = (searchText: string) => {
    resetDocumentScreen();
    if (searchText.trim() === '') {
      clearSearchInput(); // Call the function to reset search
      return;
    }
    // Filter the documents based on the search text
    const filtered = data.filter(doc =>
      doc.name.toLowerCase().includes(searchText.toLowerCase())
    );
    setDocumentList(filtered);
  };

  const resetDocumentScreen = () => {
    setDocumentList([]);
  };

  const handleGroupExpend = (groupId: string) => {
    if (expandedGroup === groupId) {
      setExpandedGroup(null); // Collapse if already expanded
    } else {
      setExpandedGroup(groupId);
      fetchFolder(); // Fetch folders when expanding
    }
  };

  const containerRef = useRef<HTMLDivElement>(null);

  const handleDragOver = (e: React.DragEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setIsDraggingOver(true);

    if (containerRef.current) {
      const { clientY } = e;
      const { top, bottom, height } =
        containerRef.current.getBoundingClientRect();
      const scrollThreshold = 100; // Adjust this value for smooth scrolling

      // Scroll up when dragging near the top
      if (clientY < top + scrollThreshold) {
        containerRef.current.scrollBy({ top: -40, behavior: 'smooth' });
      }
      // Scroll down when dragging near the bottom
      else if (clientY > bottom - scrollThreshold) {
        containerRef.current.scrollBy({ top: 40, behavior: 'smooth' });
      }
    }
  };

  const handleDragLeave = (e: React.DragEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setIsDraggingOver(false);
  };

  const handleDrop = (
    e: React.DragEvent<HTMLButtonElement>,
    newParentId: string | null
  ) => {
    e.preventDefault();
    const folderData = e.dataTransfer.getData('folder');
    const parsedFolderData = JSON.parse(folderData);
    if (parsedFolderData) {
      handleFolderMove(parsedFolderData, null);
    }
    setIsDraggingOver(false);
  };

  const handleGroupClick = () => {
    navigate(
      getTransformedUrl(routes.groupDocuments, {
        groupId: activeGroup.id,
      }),
      {
        state: { groupName: activeGroup.name },
      }
    );
  };

  return (
    <>
      {groupId && !isSharedGroup() && (
        <div className="overflow-auto max-h-[calc(100vh-350px)] border border-gray-200 rounded-lg p-2">
          {/* Search Input */}
          {/* {expandedGroup === groupId && (
            <input
              ref={searchInputRef}
              type="search"
              id="default-search"
              placeholder="Search documents"
              className="w-full p-2 border border-gray-300 rounded-md mb-2"
              onChange={e => handleSearchDocument(e.target.value)}
            />
          )} */}

          {/* Group List (Initially only groups are shown) */}
          <div
            key={groupId}
            className={`mb-2 ${isDraggingOver ? 'bg-blue-100' : ''}`}
          >
            {/* Clickable Group Name */}
            <button
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={e => handleDrop(e, null)}
              className="w-full flex items-center justify-between p-2 border border-gray-300 rounded-md bg-gray-100 hover:bg-gray-200 transition-all duration-300"
              onClick={() => handleGroupExpend(groupId)}
            >
              {/* Left: Group SVG Icon */}
              <span className="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-5 h-5 mr-2 text-gray-700"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M10 4H2v16h20V6h-8l-2-2z" />
                </svg>
                <span
                  className="font-bold text-gray-600 truncate block overflow-hidden whitespace-nowrap"
                  style={{ maxWidth: sidebarWidth - 120 }}
                  title={activeGroup?.name}
                  onClick={e => {
                    e.stopPropagation(); // Prevent handleGroupExpend from being triggered
                    handleGroupClick();
                  }}
                >
                  {activeGroup?.name}
                </span>
              </span>

              {/* Right: Collapse/Expand Icon */}
              <span
                className={`transition-transform duration-300 ${
                  expandedGroup === groupId ? 'rotate-60' : 'rotate-[-90deg]'
                }`}
              >
                ▼
              </span>
            </button>

            {/* Show folders only if this group is expanded */}
            {expandedGroup === groupId && data && (
              <div className="mt-2 ml-1 border-l pl-2">
                <Accordian data={data} onFolderMove={handleFolderMove} />
              </div>
            )}
          </div>
        </div>
      )}

      {/* Confirmation Modal */}
      <CommonModal
        isOpen={isConfirmModalVisible}
        loading={loading}
        onClose={() => setIsConfirmModalVisible(false)}
        onDelete={confirmFolderMove}
        title="Move Folder"
        body={
          <>
            Are you sure you want to move{' '}
            <strong>{moveDetails?.folderName}</strong> to
            <strong> {moveDetails?.newParentName || 'Root'}</strong>?
          </>
        }
        primaryBtnTxt="Move"
        primaryBtnClassName="btn btn-success"
      />
    </>
  );
}

export default FolderTree;
