import React, { useEffect, useRef, useState } from 'react';
import { Accordian } from './Accordian';
import { Document } from 'shared/contexts/DocumentContext';
import useDocumentService from 'shared/hooks/service/useDocumentService';
import { useAppContext } from 'shared/hooks/context/useAppContext';
import useDebounce from 'shared/hooks/useDebounce';
import Loader from 'design-web/components/Loader/Loader';
import { getTransformedUrl } from 'shared/utils/utils';
import { useNavigate, useParams } from 'react-router-dom';
import routes from 'shared/config/routes';
import { useAuthContext } from 'shared/hooks/context/useAuthContext';

interface NestedMenuProps {
  data: Document;
  onFolderMove: (folderData: Document, newParentId: string | null) => void;
}

const ChevronRightIcon: React.FC<{ onClick?: () => void }> = ({ onClick }) => (
  <svg
    onClick={onClick}
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="currentColor"
    viewBox="0 0 24 24"
    className="cursor-pointer"
  >
    <path d="M9.29 6.71a1 1 0 0 1 1.42 0L15.3 11.3a1 1 0 0 1 0 1.42l-4.59 4.59a1 1 0 0 1-1.42-1.42L13.18 12l-3.88-3.88a1 1 0 0 1 0-1.41Z" />
  </svg>
);

const KeyboardArrowDownIcon: React.FC<{ onClick?: () => void }> = ({
  onClick,
}) => (
  <svg
    onClick={onClick}
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="currentColor"
    viewBox="0 0 24 24"
    className="cursor-pointer"
  >
    <path d="M7.41 8.59 12 13.17l4.59-4.58a1 1 0 0 1 1.42 1.42l-5 5a1 1 0 0 1-1.42 0l-5-5a1 1 0 1 1 1.42-1.42Z" />
  </svg>
);

const FolderIcon: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="#316EFD"
    viewBox="0 0 24 24"
  >
    <path d="M10 4L12 6H20a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h6Z" />
  </svg>
);

const FolderSharedIcon: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="#316EFD"
    viewBox="0 0 24 24"
  >
    <path d="M10 4L12 6H20a2 2 0 0 1 2 2v3h-7a4 4 0 0 0-4 4v4H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h6ZM20 14a2 2 0 1 0-2-2 2 2 0 0 0 2 2Zm-3.92 2a4 4 0 0 0-3.08 3h7.92a4 4 0 0 0-3.08-3Z" />
  </svg>
);

export const NestedMenu: React.FC<NestedMenuProps> = ({
  data,
  onFolderMove,
}) => {
  const [expand, setExpand] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const { getFolderChildrenList } = useDocumentService();
  const { userInfo } = useAuthContext();
  const {
    setChildrenData,
    childrenData,
    refreshFolderByParentId,
    setRefreshFolderByParentId,
  } = useAppContext();
  const [folderChildren, setFolderChildren] = useState<{
    [key: string]: Document[];
  }>({});
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const { debounceVal, setCurrentValue } = useDebounce(null, 300);
  const searchInputRef = useRef<HTMLInputElement>(null); // Create a ref for the input field
  const navigate = useNavigate();
  const { groupId, folderId } = useParams();

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDraggingOver(true);
  };

  const handleDragLeave = () => {
    setIsDraggingOver(false);
  };

  const handleDrop = (
    e: React.DragEvent<HTMLDivElement>,
    newParentId: string | null
  ) => {
    e.preventDefault();
    const folderData = e.dataTransfer.getData('folder');
    const parsedFolderData = JSON.parse(folderData);
    if (folderData) {
      onFolderMove(parsedFolderData, data?.id ?? ''); // Move to root level
    }
    setIsDraggingOver(false);
  };

  const handleFolderClick = async (folderId: string) => {
    setExpand(prev => !prev);
    await fetchFolderChildren();
  };

  const isSharedGroup = () => {
    return (
      !userInfo?.groupIds.includes(groupId || '') && !userInfo?.isSuperAdmin
    );
  };

  // Fetch folders when refreshFolderByParentId changes
  useEffect(() => {
    if (refreshFolderByParentId && !isSharedGroup()) {
      fetchFolderChildren();
      setRefreshFolderByParentId(''); // Reset after fetching
    }
  }, [refreshFolderByParentId]);

  const fetchFolderChildren = async () => {
    if (!data?.id && isSharedGroup()) return;
    setLoading(true);
    try {
      const searchTerm = searchInputRef?.current?.value.trim() || '';
      const queryPayload: Record<string, any> = {
        searchTerm,
      };
      // Call backend API to fetch the child folders
      let documents =
        (await getFolderChildrenList(data?.id, groupId ?? '', queryPayload)) ||
        [];
      // Sort documents alphabetically by folder name
      documents = documents.sort((a, b) => a.name.localeCompare(b.name));

      // Store children data for each folder separately
      setFolderChildren((prev: { [key: string]: Document[] }) => ({
        ...prev,
        [data?.id]: documents,
      }));
      // store all childrens data which is epended
      setChildrenData([
        ...childrenData,
        ...documents.filter(
          doc => !childrenData.some(existing => existing.id === doc.id) // Avoid duplicates
        ),
      ]);
    } catch (error) {
      console.error('Error moving folder:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleFolderNavigateClick = (data: Document) => {
    navigate(
      getTransformedUrl(routes.groupFoldersDocuments, {
        groupId,
        folderId: data.id,
      }),
      {
        state: {
          folderName: data.name,
        },
      }
    );
  };

  return (
    <div className={`flex flex-col ${isDraggingOver ? 'bg-blue-100' : ''}`}>
      <div
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={e => handleDrop(e, null)}
        onClick={() => handleFolderClick(data?.id)}
        className="flex flex-row items-center h-[30px] hover:bg-gray-100"
      >
        <div className="flex flex-row items-center">
          {expand ? <KeyboardArrowDownIcon /> : <ChevronRightIcon />}
          {data?.id ? <FolderIcon /> : <FolderSharedIcon />}
        </div>
        <div
          onClick={e => {
            e.stopPropagation();
            handleFolderNavigateClick(data);
          }}
          className="ml-2 text-semiBase whitespace-nowrap cursor-pointer"
          title={data?.name}
        >
          {data?.name}
        </div>{' '}
        {/* Title with truncation */}
      </div>

      {/* expand children data */}
      {expand && (
        <div className="pl-4 py-1">
          {loading ? (
            <div className="flex justify-center items-center py-2">
              {<Loader className="w-8 h-8" />}
            </div>
          ) : (
            <Accordian
              data={folderChildren[data.id] || []}
              onFolderMove={onFolderMove}
            />
          )}
        </div>
      )}
    </div>
  );
};
