import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import routes from 'shared/config/routes';
import { Button, Icon } from 'design-web';
import IconMenu from 'design-web/icons/IconMenu';
import { useUserContext } from 'shared/hooks/context/useUserContext';

import Dropdown from './Dropdown';
import Notifications from './Notifications';
import LanguageSelector from './LanguageSelector';
import { useTheme } from 'shared/hooks/useTheme';
import { useAuthContext } from 'shared/hooks/context/useAuthContext';
import belmontLogo from '../../assets/images/belmontLogo.svg';
import { getUserFullName } from 'shared/utils/utils';
import { User } from 'shared/contexts/UserContext';
import UserProfileImage from '../../components/UserProfileImage';

const profileMenu = [
  { name: 'Setting', route: routes.setting, icon: 'Setting' },
  { name: 'Logout', route: routes.logout, icon: 'Logout' },
];

function Header() {
  const { theme, sidebar, toggleSidebar, isMobile } = useTheme();
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [isLanguageOpen, setIsLanguageOpen] = useState(false);
  const { t } = useTranslation();
  const { userInfo: user } = useAuthContext();
  const navigate = useNavigate();

  const handleNavigate = (route: string) => {
    // if route is logout then reload
    if (route === routes.logout) {
      window.location.href = route;
    } else {
      navigate(route);
    }
  };
  return (
    <header className="z-40 bg-white">
      <div className="shadow-sm">
        <div className="relative bg-white flex  w-full items-center px-5 py-2.5 dark:bg-black">
          <div className="horizontal-logo flex lg:hidden justify-between items-center ltr:mr-2 rtl:ml-2">
            {!isMobile && (
              <>
                <Link to="/" className="main-logo flex items-center shrink-0">
                  <img
                    src={`${belmontLogo}`}
                    alt="Belmont Logo"
                    className={`w-40`}
                  />
                </Link>
                <button
                  type="button"
                  className="collapse-icon flex-none dark:text-[#d0d2d6] hover:text-primary dark:hover:text-primary flex lg:hidden ltr:ml-2 rtl:mr-2 p-2 rounded-full bg-white-light/40 dark:bg-dark/40 hover:bg-white-light/90 dark:hover:bg-dark/60"
                  onClick={() => {
                    toggleSidebar();
                  }}
                >
                  <IconMenu className="w-5 h-5" />
                </button>
              </>
            )}

            {isMobile && (
              <>
                <button
                  type="button"
                  className="collapse-icon flex-none dark:text-[#d0d2d6] hover:text-primary dark:hover:text-primary flex lg:hidden ltr:ml-2 rtl:mr-2 p-2 rounded-full bg-white-light/40 dark:bg-dark/40 hover:bg-white-light/90 dark:hover:bg-dark/60"
                  onClick={() => {
                    toggleSidebar();
                  }}
                >
                  <IconMenu className="w-5 h-5" />
                </button>
                <Link
                  to="/"
                  className="main-logo flex items-center shrink-0 pl-16"
                >
                  <img
                    src={`${belmontLogo}`}
                    alt="Belmont Logo"
                    className={`w-28`}
                  />
                </Link>
              </>
            )}
          </div>
          {/* <div className="text-xl font-bold"></div> */}
          <div className="flex justify-end flex-1 items-center space-x-4">
            {/* Language Dropdown */}
            {/* <Dropdown
              trigger={
                <button
                  onClick={() => setIsLanguageOpen(!isLanguageOpen)}
                  className="flex justify-center items-center w-8 h-8 bg-gray-100 rounded-full hover:bg-gray-200"
                >
                  <Icon
                    name="I18n"
                    height={20}
                    width={20}
                    isHovered={isLanguageOpen}
                  />
                </button>
              }
              isOpen={isLanguageOpen}
              setIsOpen={setIsLanguageOpen}
            >
              <LanguageSelector />
            </Dropdown> */}

            {/* Notification Dropdown */}
            {/* <Dropdown
              trigger={
                <button
                  onClick={() => setIsNotificationOpen(!isNotificationOpen)}
                  className="flex justify-center items-center w-8 h-8 bg-gray-100 rounded-full hover:bg-gray-200"
                >
                  <Icon
                    name="Notification"
                    height={20}
                    width={20}
                    isHovered={isNotificationOpen}
                  />
                </button>
              }
              isOpen={isNotificationOpen}
              setIsOpen={setIsNotificationOpen}
            >
              <Notifications />
            </Dropdown> */}

            {/* Settings Button */}
            <Button
              className="flex justify-center items-center w-9 h-9 rounded-full bg-gray-200 hover:bg-gray-300"
              onClick={() => handleNavigate('/setting')} // Replace with your settings function
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="fill-current text-gray-600 transition-colors duration-300 hover:text-primary-lightBlue"
              >
                <path d="M0 0h24v24H0z" fill="none" />
                <path
                  d="M19.14,12.94c0.04-0.3,0.06-0.61,0.06-0.94s-0.02-0.64-0.06-0.94l2.03-1.58c0.19-0.15,0.24-0.42,0.12-0.64l-1.92-3.32
          c-0.12-0.22-0.37-0.3-0.6-0.22l-2.39,0.96c-0.5-0.39-1.05-0.73-1.66-1.01L14.72,2.4c-0.05-0.23-0.24-0.4-0.48-0.4h-4.48
          c-0.24,0-0.44,0.17-0.48,0.4L8.49,5.25c-0.61,0.28-1.17,0.62-1.66,1.01L4.44,5.3c-0.23-0.09-0.48,0-0.6,0.22L1.92,8.84
          c-0.12,0.22-0.07,0.49,0.12,0.64l2.03,1.58C4.02,11.36,4,11.67,4,12s0.02,0.64,0.06,0.94L2.03,14.52c-0.19,0.15-0.24,0.42-0.12,0.64
          l1.92,3.32c0.12,0.22,0.37,0.3,0.6,0.22l2.39-0.96c0.5,0.39,1.05,0.73,1.66,1.01l0.79,2.85c0.05,0.23,0.24,0.4,0.48,0.4h4.48
          c0.24,0,0.44-0.17,0.48-0.4l0.79-2.85c0.61-0.28,1.17-0.62,1.66-1.01l2.39,0.96c0.23,0.09,0.48,0,0.6-0.22l1.92-3.32
          c0.12-0.22,0.07-0.49-0.12-0.64L19.14,12.94z M12,15.5c-1.93,0-3.5-1.57-3.5-3.5s1.57-3.5,3.5-3.5s3.5,1.57,3.5,3.5
          S13.93,15.5,12,15.5z"
                />
              </svg>
            </Button>

            {/* Profile Dropdown */}
            <Dropdown
              trigger={
                <button
                  onClick={() => setIsProfileOpen(!isProfileOpen)}
                  className="flex justify-center items-center w-8 h-8 rounded-full"
                >
                  <UserProfileImage
                    user={user as User}
                    imgClassName={'w-8 h-8 rounded-full'}
                    avatarClassName={'w-8 h-8'}
                    initialsLength={2}
                    fontSize={'14px'}
                  />
                  {/* <Icon
                    name="User"
                    height={20}
                    width={20}
                    isHovered={isProfileOpen}
                  /> */}
                </button>
              }
              isOpen={isProfileOpen}
              setIsOpen={setIsProfileOpen}
            >
              <ul className="text-dark dark:text-white-dark !py-0 w-[230px] font-semibold dark:text-white-light/90">
                <li>
                  <div className="flex items-center px-4 py-4">
                    <UserProfileImage
                      user={user as User}
                      imgClassName={'w-10 h-10 rounded-md mr-2'}
                      avatarClassName={'w-12 h-12'}
                      initialsLength={2}
                      fontSize={'16px'}
                    />
                    <div className="ltr:pl-2 rtl:pr-2 truncate">
                      <h4 className="text-base">
                        {getUserFullName(user as User)}
                      </h4>
                      <div
                        className="text-black/60 hover:text-primary dark:text-dark-light/60 dark:hover:text-white whitespace-nowrap text-ellipsis overflow-hidden"
                        title={user?.email}
                      >
                        {user?.email}
                      </div>
                    </div>
                  </div>
                </li>
                {profileMenu.map(item => (
                  <li
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                    onClick={() => handleNavigate(item.route)}
                    key={item.route}
                  >
                    {t(item.name)}
                  </li>
                ))}
              </ul>
            </Dropdown>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
